export default {
  aboutInfo: [
    {
      value: 0,
      name: 'Dr. Ooi',
      info: 'Dr. Ooi memperoleh gelar Doktor di bidang Administrasi Bisnis dan manajemen dari University of outh Australia. Dia berperan penting dalam memulai perusahaan dan mengarahkan operasi dan rencana strategisnya. Dia membawa lebih dari 20 tahun pengalaman manajemen risiko dan kepatuhan dalam kepemimpinannya yang panjang dan berperan sebagai visioner dalam mengawasi strategi bisnis, pengembangan bisnis, alur bisnis, operasi bisnis, dan dukungan di seluruh wilayah Asia-Pasifik dari perusahaan solusi perbankan yang terdaftar di NASDAD. Sebelum ini, ia adalah seorang akuntan staf untuk organisasi asuransi kesehatan terbesar di amerika serikat dan menjadi seorang analis keuangan untuk bank tabungan A.S. di Los Angeles selama bertahun-tahun.'
    },
    {
      value: 1,
      name: 'Arnoldyth Rodes Medo',
      info: 'Arnold memperoleh gelar Sarjana Ekonomi dari Universitas Muhammadiyah Yogyakarta, jurusan Akuntansi. Beliau memiliki pengalaman dari beberapa Lembaga Keuangan baik dalam maupun luar negeri sejak tahun 2012 dan juga merupakan sosok pionir dalam dunia Fintech di Indonesia. Hingga saat ini, beliau memfokuskan keahliannya pada beberapa bidang seperti Risk Management, Internal Audit and Fraud Investigation, Sales and Marketing, Market Analysis dan Target Market Analysis.'
    },
    {
      value: 2,
      name: 'Satriya Putra',
      info: 'Satriya memperoleh gelar Sarjana Ekonomi dari Universitas Trisakti, Jakarta, jurusan Manajemen. Beliau mempunyai karir cemerlang sebagai Direktur di beberapa perusahaan konsultan bisnis yang berkecimpung di bidang risk management dan Manajemen Informasi Teknologi dengan klien-kiennya baik dari perbankan maupun dari perusahaan jasa keuangan lainnya.'
    },
    {
      value: 3,
      name: 'Alan Yazid Ali',
      info: 'Alan Yazid Ali memperoleh gelar Master di bidang MIS, Bisnis International, dan Manajemen Operasi dari Bowling Green State University, Ohio, America. Dia berperan penting dalam memulai perusahaan dan mengarahkan operasi serta rencana strategisnya. Dia membawa lebih dari 20 tahun pengalaman manajemen risiko dan kepatuhan dalam kepemimpinannya yang panjang dan memainkan peran visioner dalam mengawasi strategi bisnis, pengembangan bisnis, arus bisnis, operasi bisnis, dan dukungan di PT Bank Ekspor Indonesia (persero). Susunan Pengurus Perusahaan berdasarkan Akta Pernyataan Keputusan Rapat PT Smartec Teknologi Indonesia Nomor 6 tanggal 8 September 2022 dan telah mendapat diberitahukan kepada Kementerian Hukum dan HAM melalui surat Penerimaan Pemberitahuan Perubahan Data Perseroan Nomor AHU-AH.01.09-0054861'
    },
    {
      value: 4,
      name: 'Ooi Chuan Hock',
      info: 'Clement Ooi memperoleh gelar Doktor di bidang Administrasi Bisnis dan manajemen dari University of South Australia. Memiliki peran penting dalam memulai perusahaan dan mengarahkan kegiatan operasional perusahaan serta rencana strategis yang harus dijalani oleh perusahaan. Memiliki lebih dari 20 tahun pengalaman manajemen risiko dan kepatuhan dalam kepemimpinannya yang panjang dan memainkan peran visioner dalam mengawasi strategi bisnis, pengembangan bisnis, arus bisnis, operasi bisnis, dan dukungan di seluruh wilayah Asia-Pasifik dari perusahaan solusi perbankan yang terdaftar di NASDAQ .'
    },
    {
      value: 5,
      name: 'Satriya Putra',
      info: 'Satriya memperoleh gelar Sarjana Ekonomi dari Universitas Trisakti, Jakarta, jurusan Manajemen. Memiliki pengalaman cemerlang pada perusahaan konsultan bisnis yang berkecimpung pada bidang risk management dan Manajemen Informasi Teknologi dengan klien-kiennya baik dari perbankan maupun dari perusahaan jasa keuangan lainnya.'
    },
  ],
  faqList: [
    {
      value: 1,
      question: 'Mengapa Saya Harus Meminjam di Aplikasi BantuSaku?',
      answer: 'BantuSaku adalah aplikasi Pinjaman Online Kredit Cepat yang telah terdaftar dan berizin di OJK. Variatif produk pinjaman yang ditawarkan oleh BantuSaku menjadi keunikan tersendiri bagi para calon konsumen. Pilihan produk pinjaman uang disediakan dengan bunga rendah yang telah disesuaikan dengan ketentuan yang telah diberikan oleh Pemerintah terhadap para penyelenggara peer-to-peer lending. Selain itu, BantuSaku menawarkan proses pinjaman cepat cair yang sangat memudahkan para peminjam untuk melakukan pinjaman secara instant. Aplikasi BantuSaku telah diunduh lebih dari lebih dari 5 juta di Play Store dan menerima banyak sekali ulasan positif dari para penggunanya.'
    },
    {
      value: 2,
      question: 'Keuntungan Melakukan Pinjaman Lewat Aplikasi BantuSaku?',
      answer: 'Untuk dapat mengajukan Pinjaman di BantuSaku, berikut adalah persyaratan yang perlu Anda persiapkan: ',
      list:[
        '1.Syarat Mudah: Dapatkan pinjaman uang tanpa jaminan dengan mudah. Cukup sediakan dokumen pribadi (KTP dan Rekening Pribadi Atas Nama Peminjam).',
        '2.Dana Cepat Cair: Dana pinjaman bisa cair dalam waktu 5 menit. (apabila syarat dan ketentuan dokumen terpenuhi).',
        '3.Cicilan Tetap dan Bunga Terjangkau: Dapatkan pinjaman uang dengan bunga terjangkau dan nominal cicilan yang tetap setiap waktu pembayarannya.',
        '4.Teknologi Terbukti dan Aman: BantuSaku telah menggunakan teknologi Artificial Intelligence (AI) dan Big Data sehingga semua proses pinjam uang dijamin aman dan terpercaya.'
      ]
    },
    {
      value: 3,
      question: 'Syarat Pengajuan Pinjaman Online di Aplikasi BantuSaku?',
      answer: '',
      list:[
        '1.Merupakan Warga Negara Indonesia (WNI) dengan KTP yang sah.',
        '2.Memiliki Rekening Milik Pribadi.',
        '3.Berusia Minimal 21 tahun dan sudah memiliki KTP.',
        '4.Informasi yang diberikan harus benar, akurat, dan lengkap.',
        '5.Membayar cicilan tepat waktu setiap sesuai tanggal peminjaman yang sudah terdapat di perjanjian kontrak.'
      ]
    },
    {
      value: 4,
      question: 'Kapan Saya Bisa Mengajukan Pinjaman di BantuSaku?',
      answer: 'Anda dapat Melakukan Pengajuan Pinjaman Kapanpun dan Dimanapun hanya dengan Menggunakan Smartphone Milik Anda.'
    },
    {
      value: 5,
      question: 'Apakah Saya bisa Datang ke Kantor BantuSaku untuk melakukan Pengajuan Pinjaman di Bantusaku?',
      answer: 'Tidak bisa. Anda Hanya dapat dapat melakukan pengisian data dan pengajuan pinjaman melalui aplikasi BantuSaku.'
    },
    {
      value: 6,
      question: 'KTP Saya Memiliki Alamat yang Berbeda dengan Domisili Saya Saat ini. ApakahSaya Masih Dapat Mengajukan Pinjaman?',
      answer: 'Anda Masih Dapat Mengajukan Pinjaman di BantuSaku, Selama Domisili Masih di Wilayah Jangkauan Kami yaitu Indonesia.'
    },
    {
      value: 7,
      question: 'Apakah Saya Disa Mengajukan Pinjaman di BantuSaku dengan Menggunakan Nama Saya sebagai Peminjam, tetapi Menggunakan Rekening Orang lain(anak/suami) sebagai tempat penerima Dana?',
      answer: 'Anda Dapat Mengajukan Pinjaman, Jika KTP Blanko Anda masih dalam proses pembuatan, Maka Anda dapat Menggunakan Surat Keterangan (resi KTP).'
    },
    {
      value: 8,
      question: 'Berapa lama Proses Verifikasi Data Pinjaman di BantuSaku?',
      answer: 'Perihal Pengajuan Dana Pinjaman yang Anda Ajukan, Akan dianalisa terlebih dahulu oleh Tim Verifikasi Kami Maksimal 1×24 Jam (1 Hari Jam Kerja).'
    }
  ],
}